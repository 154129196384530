<template>
  <div class="stop" v-if="!isProcessing">
    <div class="stop__text">
      <h2 class="stop__text__title">ただいま一時停止中です。</h2>
      <v-icon class="stop__text__icon">remove_circle_outline</v-icon>
      <p class="stop__text__explain">申し訳ございませんが、サービス再開までお待ちください。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'stop',
  mounted () {
    // 停止中じゃない場合のアクセスは弾く
    if (!this.isStop) {
      this.$router.push({ name: 'home' })
      return
    }

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Object} 最新の停止情報
     */
    stop () {
      return this.$store.getters['stops/stop']
    },
    /**
     * @return {Boolean} 停止中かどうか
     */
    isStop () {
      return this.stop && !this.stop.endAt
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";
@import "@/assets/sass/fontfamily.scss";

.stop {
  position: relative;
  width: 100vw;
  max-width: $max_width;
  min-height: 100vh;
  padding: $padding_width $padding_width;
  text-align: center;
  background-color: $gray_lighten_color;
  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    &__title {
      font-size: 2rem;
    }
    &__icon {
      margin-top: 20px;
      font-size: 14rem;
      &.theme--light.v-icon {
        font-family: $material-outlined;
        color: $black_lighten_color;
      }
    }
    &__explain {
      margin-top: 20px;
      font-size: 1.6rem;
      text-align: left;
    }
  }
}
</style>
